import { useContext } from 'react';
import { GlobalContext } from '../../components/data/GlobalContext';


// const imgUrl = require.context('../../assets/images', true);


function RedesContent(props) {
    const dbData = useContext(GlobalContext);
    return (
      <>
        <div className="flex text-center items-center pb-5">
          {dbData?.gmb?.link.length > 1 ? (
            <a
              href={dbData?.gmb?.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={dbData?.gmb?.img} alt="Not found" width={"200px"} />
            </a>
          ) : null}
        </div>
        <h2 className="text-center text-capitalize pb-5">Follow us</h2>
        <div className="contentRedes">
          {dbData.dbSocialMedia.directorios &&
          dbData.dbSocialMedia.directorios[0].name !== " "
            ? dbData.dbSocialMedia.directorios.map((items, index) => {
                return (
                  <div key={index}>
                    <a href={items.url} target="_blank" rel="noreferrer">
                      <img src={items.image} alt={items.name} />
                    </a>
                  </div>
                );
              })
            : null}
          {dbData.dbSocialMedia.redes &&
          dbData.dbSocialMedia.redes[0].name !== " "
            ? dbData.dbSocialMedia.redes.map((items, index) => {
                return (
                  <div key={index}>
                    <a href={items.url} target="_blank" rel="noreferrer">
                      <img src={items.image} alt={items.name} />
                    </a>
                  </div>
                );
              })
            : null}
        </div>
      </>
    );
}

export default RedesContent;