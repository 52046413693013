// import { useContext, useState } from "react";
// import { GlobalContext } from "../data/GlobalContext";
import { Link } from "react-router-dom";

import dbMenu from "../data/dbMenu";




const NavsLink = ({ extraClassName }) => {

    const handleDropdownStatus = (e) => {
        let clickedItem = e.currentTarget.parentNode;
        clickedItem.querySelector(".dropdown-list").classList.toggle("show");
    };




    return (
        <ul className={`main-menu__list ${extraClassName}`}>
            {
                dbMenu.dbMenu.map((item, index) => {
                    return (
                        item.submenu ?
                            <li key={index} className='dropdown'>
                                <Link to={item.link}
                                >{item.name}</Link>
                                <button aria-label="dropdown toggler" onClick={handleDropdownStatus}>
                                    <i className="fa fa-angle-down"></i>
                                </button>
                                <ul className="dropdown-list">
                                    {
                                        item.submenu.map((subItem, index) => {
                                            return (
                                                <li key={index}>
                                                    <Link
                                                        to={subItem.link}
                                                    >
                                                        {subItem.name}
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </li>
                            :
                            item.url ?
                                <li key={index}>
                                    <a href={item.url} target="_blank" rel="noreferrer">{item.name}</a>
                                </li>
                                :
                                <li key={index}>
                                    <Link to={item.link}>{item.name}</Link>
                                </li>
                    )
                })
            }
        </ul>

    );
}

export default NavsLink;