import React, { useContext } from 'react'
import { GlobalContext } from '../data/GlobalContext'

const VideoContent = () => {
  const dbData = useContext(GlobalContext);
  return (
    <div className='contentVideo' style={{ backgroundImage: `url("${dbData.stock[0]}")` }}>
      <div className='contentVideo__contnetImg'>
        <img
          src={dbData.dbPrincipal.logo}
          alt='logo'
          loading='lazy'
        />
      </div>
      <div className='contentVideo__video'>
        <iframe
          className="embed-responsive-item" id="ytplayer"
          type="text/html"
          src="https://www.youtube.com/embed/dL5ZJi64a0k?&autoplay=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=dL5ZJi64a0k"
          frameborder="0" allowfullscreen title='iframe'></iframe>
      </div>
    </div>
  )
}

export default VideoContent