import { useContext } from "react";
import { GlobalContext } from "./data/GlobalContext";
import { Col, Container, Row } from "react-bootstrap";

import { GrMap } from 'react-icons/gr';
import { FiPhoneCall } from 'react-icons/fi';
import { ImCalendar } from 'react-icons/im';


const imgUrl = require.context('../assets/images', true);


const ContactCard = () => {
    const dbData = useContext(GlobalContext);
    return (
        <Container>
            <Row>
                <Col sm={12} md={4}>
                    <div
                        className="contact-card d-flex flex-column text-center justify-content-center align-items-center background-secondary"
                        style={{ backgroundImage: `url(${imgUrl(`./stock/contact-card-bg-1-1.png`)})` }}
                    >
                        <i aria-label="contact icon"> <GrMap /></i>
                        <h3>Location</h3>
                        <p>
                            {dbData.dbPrincipal.location[0].address}
                        </p>
                    </div>
                </Col>
                <Col sm={12} md={4}>
                    <div
                        className="contact-card d-flex flex-column text-center justify-content-center align-items-center background-primary"
                        style={{ backgroundImage: `url(${imgUrl(`./stock/contact-card-bg-1-1.png`)})` }}
                    >
                        <i aria-label="contact icon"> <FiPhoneCall /></i>
                        <h3>Phone</h3>
                        <p>
                            {dbData.dbPrincipal.phones.map((phone, index) => {
                                return (
                                    <a href={`tel:${phone.phone}`} key={index}>
                                        <span>
                                            {phone.phone}
                                            <br />
                                        </span>
                                    </a>
                                );
                            })
                            }
                        </p>
                    </div>
                </Col>
                <Col sm={12} md={4}>
                    <div
                        className="contact-card d-flex flex-column text-center justify-content-center align-items-center background-special"
                        style={{ backgroundImage: `url(${imgUrl(`./stock/contact-card-bg-1-1.png`)})` }}
                    >
                        <i aria-label="contact icon"><ImCalendar /></i>
                        <h3>workdays</h3>
                        <p>
                            {dbData.dbPrincipal.workdays[0].day} <br />
                            {dbData.dbPrincipal.workHours[0].hour}
                        </p>
                    </div>
                </Col>
            </Row>
            <div className="milesContent">
                {
                    dbData.dbPrincipal.miles !== ' ' ?
                        <p>

                            we cover {dbData.dbPrincipal.miles} miles around {dbData.dbPrincipal.location[0].address}
                        </p>
                        :
                        <p>
                            we cover the entire state of {dbData.dbPrincipal.location[1].address}
                        </p>

                }
            </div>
        </Container>
    );
};

export default ContactCard;