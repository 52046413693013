import { useContext } from "react";
import { GlobalContext } from "../components/data/GlobalContext";
import Layout from "../components/Layout";
import PagesHeader from "../components/PagesHeader";
import GalleryContent from "../components/gallery/GalleryContent";

const Gallery = () => {
    const dbData = useContext(GlobalContext);
    return (
        <Layout pageTitle="Gallery" >
            <PagesHeader title="Gallery" crumbTitle="Gallery" pageHeaderBg={dbData.stock[4]} />
            {
                dbData?.landings?.length > 0 ?
                    dbData?.landings?.map((item, index) => {
                        return (
                            <div>
                                <div className="text-center py-5" key={index}>
                                    <h1 className="text-capitalize">our recent projects</h1>
                                    <h2>{item.name}</h2>
                                </div>
                                <GalleryContent galleryImages={item.gallery} />
                            </div>
                        )
                    })
                    :
                    <div>
                        <div className="text-center py-5">
                            <h1 className="text-capitalize">our recent projects</h1>
                        </div>
                        <GalleryContent galleryImages={dbData.gallery.length > 0 ? dbData.gallery : dbData.stock} />
                    </div>
            }
        </Layout>
    );
}
export default Gallery;