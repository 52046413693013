import { useContext } from "react";
import { GlobalContext } from "../data/GlobalContext";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import * as FaIcons from 'react-icons/fa';
import BotonWhatsapp from '../botonWhatsapp/BotonWhatsapp';
import ContadorVisita from "../contadorVisita/ContadorVisita";


const imgUrl = require.context('../../assets/images', true);

const Footer = () => {
    const dbData = useContext(GlobalContext);
    return (
        <section className="site-footer">
<div class="elfsight-app-8d64afb0-e3f8-4c68-8834-b7f51c459249"></div>
            <div className="main-footer pt-142 pb-80">
                <Container>
                    <Row>
                        <Col lg={3} md={6} sm={12}>
                            <div className="footer-widget mb-40 footer-widget__about">
                                <Link to='/'>
                                    <img src={`${dbData.dbPrincipal.logo}`} width="250" alt="logo" />
                                </Link>
                                <p></p>
                                <ul className="list-unstyled footer-widget__contact">
                                    <li>
                                        <h5 className="text-white">Phone</h5>
                                        {
                                            dbData.dbPrincipal.phones.map((item, index) => {
                                                return (
                                                    <div>
                                                        <a key={index} href={`tel:+1${item.phone}`}>
                                                            <i className="azino-icon-telephone"></i>
                                                            {item.phone}
                                                        </a>
                                                    </div>
                                                )
                                            })
                                        }
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={3} md={6} sm={12}>
                            <div className="footer-widget footer-widget__link mb-40">
                                <h3 className="footer-widget__title">Services</h3>
                                <ul className="list-unstyled footer-widget__link-list">
                                    {
                                        dbData.dbServices.slice(0,8).map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <Link to='/services'>
                                                        {item.name}
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </Col>
                        <Col lg={3} md={6} sm={12}>
                            <div className="footer-widget mb-40 footer-widget__blog">
                                <h3 className="footer-widget__title">Contact</h3>
                                <ul className="list-unstyled footer-widget__blog">
                                    <li>
                                        <img src={imgUrl(`./stock/calendar.png`)} width='50' alt="" />
                                        <p className="text-capitalize">Workdays</p>
                                        <h3>
                                            {dbData.dbPrincipal.workdays[0].day}
                                        </h3>
                                    </li>
                                    <li>
                                        <img src={imgUrl(`./stock/clock.png`)} width='50' alt="" />
                                        <p className="text-capitalize">work hours</p>
                                        <h3>
                                            {dbData.dbPrincipal.workHours[0].hour}
                                        </h3>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={3} md={6} sm={12}>
                            <div className="footer-widget mb-40 footer-widget__newsletter">
                                <h3 className="footer-widget__title">About</h3>
                                <p>{dbData.dbAbout[0].text.substring(0, 240)}</p>
                                <div className="d-flex  justify-content-end mt-3">
                                    <button className="thm-btn text-capitalize ">
                                        free estimate
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <ScrollLink
                        to="wrapper"
                        smooth={true}
                        duration={500}
                        className="scroll-to-top"
                    >
                        <i className="far fa-angle-up"></i>
                    </ScrollLink>
                    <p>© Copyright 2022 by {dbData.dbPrincipal.name}</p>
                    <div className="footer-social">
                        {
                            dbData.dbSocialMedia.redes.map((item, index) => {
                                const Social =
                                    FaIcons[
                                    item.icon
                                    ];
                                return (
                                    <a
                                        key={index}
                                        href={item.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Social />
                                    </a>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <BotonWhatsapp />
            <ContadorVisita />
        </section>
    )
}

export default Footer;